import React from 'react';

import AdminLayout from '../../adminlayout/index';

import SEO from 'components/seo/index';
// import Side from 'layout/sidebaritem';

import Speciestatuspage from 'components/admin/speciesstatuspage';

const Speciesstatus = () => {
  return (
    <>
      <SEO title='Forests By Heartfulness admin' />
      <AdminLayout>
        <div>
          <Speciestatuspage />
        </div>
      </AdminLayout>
    </>
  );
};

export default Speciesstatus;
