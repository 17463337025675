import React, { useState, useEffect } from 'react';
import 'assets/styles/pages/sidebar.scss';
import 'assets/styles/pages/dashboardstatus.scss';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { Link, navigate } from 'gatsby';
import Form from 'react-bootstrap/Form';
import donatedtree from 'assets/images/admin/donated-tree.svg';
import editiconpencil from 'assets/images/admin/edit-icon.svg';
import binicon from 'assets/images/admin/recycle-bin.png';
//import sidebar css from react-pro-sidebar module and our custom css
import 'react-pro-sidebar/dist/css/styles.css';
import 'assets/styles/pages/customheader.scss';

import AdminService from 'services/admin/admin.service';
import { toast, ToastContainer } from 'react-toastify';

import Pagination from "react-js-pagination";

import noimage from "assets/images/noImage.png";
import { doAdminLogout } from './login';
import { isMobile } from 'react-device-detect';

//import FBHPagination from 'components/common/FBHPagination';

export default function Speciestatuspage() {
  const adminApi = new AdminService();
  const [speciesList, setSpeciesList] = useState([]);
  const [specieName, setSpecieName] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [trackId, setTrackId] = useState('')
  const [trackDelete, setTrackDelete] = useState(false)
  const [pageCount, setPageCount] = useState(10)
  const [onSearch, setOnSearch] = useState(false);

  const deleteOpen = (data) => {
    setTrackId(data);
    setTrackDelete(true);
  };
  const trackClose = () => {
    setTrackDelete(false);
    setTrackId("");
  };

  const deleteProject = async () => {
    setLoading(true);
    await adminApi
      .deleteAdminSpecies(trackId)
      .then((data) => {
        if (data.status === 200) {
          setLoading(false);
          toast.success("Species Deleted Successfully");
          setTrackDelete(false);
          listSpecies();
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };
  const handlePageChange = async (pageNumber) => {
    await setActivePage(pageNumber);
    let inputs = {
      pageNumber: pageNumber - 1,
      pageSize: pageCount,
      name: specieName,
      status: statusValue,
      toBeFiltered: statusValue !== "" || specieName !== "" ? true : false,
    };
    setLoading(true);
    await adminApi
      .listAdminSpecies({ inputs })
      .then((data) => {
        if (data.status === 200) {
          setSpeciesList(data.data);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          toast.error(error.response.data.message);
        }
      });
  };
  const validationMessage = (data) => {
    if (data.response.status === 401) {
      toast.error("Authentication failed");
      setTimeout(() => {
        doAdminLogout();
        navigate("/admin/");
      }, 3000);
    } else if (data.response.status === 403) {
      toast.error("Un Authorized user");
      setTimeout(() => {
        doAdminLogout();
        navigate("/admin/");
      }, 3000);
    } else {
      toast.error(data.response.data.message);
    }
  };

  const listSpecies = async () => {
    let inputs = {
      pageNumber: 0,
      pageSize: pageCount,
      name: "",
      status: "",
      toBeFiltered: false,
    };
    setLoading(true);
    await adminApi
      .listAdminSpecies({ inputs })
      .then((data) => {
        if (data.status === 200) {
          setSpeciesList(data.data);
          setActivePage(1);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          validationMessage(error);
        }
      });
  };

  const editSpecies = (data) => {
    const editData = data;
    navigate("/admin/speciesedit", { state: { editData } });
  };
  const searchSpecies = async (e) => {
    const value = e.target.value;
    setSpecieName(value);
    if (value.length > 2) {
      let inputs = {
        pageNumber: 0,
        pageSize: pageCount,
        name: value,
        status: statusValue,
        toBeFiltered: true,
      };
      setLoading(true);
      const plantationResponse = await adminApi.listAdminSpecies({ inputs });

      if (plantationResponse !== undefined) {
        if (plantationResponse.status === 200) {
          setSpeciesList(plantationResponse.data);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      } else {
        setLoading(false);
      }
    }
    if (value.length === 0) {
      setStatusValue("");
      listSpecies();
    }
  };
  const statusSearch = async (e) => {
    const value = e.target.value;
    setStatusValue(value);
    setActivePage(1);
    let inputs = {
      pageNumber: 0,
      pageSize: pageCount,
      name: specieName,
      status: value,
      toBeFiltered: value === "" ? false : true,
    };
    setLoading(true);
    const plantationResponse = await adminApi.listAdminSpecies({ inputs });

    if (plantationResponse !== undefined) {
      if (plantationResponse.status === 200) {
        setSpeciesList(plantationResponse.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Something went wrong");
      }
    } else {
      setLoading(false);
    }
  };

  const pageCountChange = async (e) => {
    let targetValue = e.target.value;
    setPageCount(targetValue);
    let inputs = {
      pageNumber: activePage - 1,
      pageSize: targetValue,
      name: specieName,
      status: statusValue,
      toBeFiltered: statusValue !== "" || specieName !== "" ? true : false,
    };
    setLoading(true);
    await adminApi
      .listAdminSpecies({ inputs })
      .then((data) => {
        if (data.status === 200) {
          setSpeciesList(data.data);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    listSpecies();
  }, []);
  return (
    <>
      <div className="dashboard-status-wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="dark-theme-green margin-btm"> SPECIES</div>
          </div>
          <div className="col-md-3">
            <div className="project-status-select">
              <Form.Control
                className={
                  onSearch === true
                    ? "projectselect"
                    : "projectselect search-box-input"
                }
                type="text"
                placeholder=""
                onChange={(e) => {
                  searchSpecies(e);
                  setOnSearch(true);
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="project-status-select">
              <Form.Select
                className="all-status-input-box"
                aria-label="All status"
                value={statusValue}
                onChange={(e) => {
                  statusSearch(e);
                }}
              >
                <option value="">All</option>
                <option value="2">Active</option>
                <option value="1">Inactive</option>
              </Form.Select>
            </div>
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-3">
            <Link
              to="/admin/addspecies"
              className="btn btn-white-button fl-right"
            >
              + Add Species
            </Link>
          </div>
        </div>
        <div className="row mg-top20">
          {speciesList.species &&
            speciesList.species.map((item) => {
              return (
                <div className="col-md-6">
                  <div className="dashboard-card-wrapper">
                    <div className="wid-40 padding-10" id="plantList">
                      {item.speciesImageUrls && item.speciesImageUrls ? (
                        <img
                          className=""
                          src={item.speciesImageUrls[0]}
                          alt="bellicon"
                          height="100%"
                          width="100%"
                        />
                      ) : (
                        <img src={noimage} alt="default Image" />
                      )}
                    </div>
                    <div className="dashboard-card-body wid-60">
                      <div className="dis-title-status">
                        <h5 className="heading-text"> {item.speciesName}</h5>
                        <span className="status-text right-hightlight-msg">
                          {item.status === "INACTIVE" ? (
                            <span style={{ color: "red" }}>In Active</span>
                          ) : item.status === "ACTIVE" ? (
                            "Active"
                          ) : null}
                        </span>
                      </div>
                      <div className="dis-title-status ">
                        <span className="small-tree-text mg-right40">
                          <img
                            className="img-fluid"
                            src={donatedtree}
                            alt="bellicon"
                          />
                          {item.scientificName}
                        </span>
                      </div>
                      <div className="date-time-text margin-admin-10">
                        {" "}
                        {item.naturalHabitat}
                      </div>
                      {isMobile ? <div className="mg-top35"></div> : null}

                      <div
                        className={
                          isMobile
                            ? `item-direction-wr1`
                            : `item-direction-wr11`
                        }
                      >
                        {/* <p className='date-time-text p-wid70'> Tue, Apr 20, 2021, 12:45 PM </p> */}
                        <span className="dis-icon-proper">
                          <button
                            className="circle-view mg-right10 edit-pencil-green"
                            onClick={() => {
                              editSpecies(item);
                            }}
                          >
                            <img
                              className="img-fluid"
                              src={editiconpencil}
                              alt="bellicon"
                            />
                          </button>
                          <button
                            className="circle-view delete-bin"
                            onClick={() => {
                              deleteOpen(item.id);
                            }}
                          >
                            <img
                              className="img-fluid bin-w-pad"
                              src={binicon}
                              alt="bellicon"
                            />
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {speciesList.totalNoOfRecords === 0 ? <p>No Records Found</p> : null}
        {loading === false && speciesList.totalNoOfRecords > 10 ? (
          <div className="row">
            <div className="col-md-4 float-start">
              Showing{" "}
              {activePage === 1 ? activePage : (activePage - 1) * pageCount} to{" "}
              {Number(activePage) * pageCount} of {speciesList.totalNoOfRecords}{" "}
              items{" "}
            </div>
            <div className="col-md-5 align-me">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={pageCount}
                totalItemsCount={speciesList.totalNoOfRecords}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            </div>
            <div className="col-md-3 float-right" id="select-width">
              {/* <FBHPagination activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={speciesList.totalNoOfRecords}
              pageRangeDisplayed={5}
            handlePageChange={handlePageChange} /> */}
              <span>
                {" "}
                Show{" "}
                <Form.Select
                  onChange={pageCountChange}
                  id="select-width"
                  value={pageCount}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </Form.Select>{" "}
                entries
              </span>
            </div>
          </div>
        ) : null}
      </div>
      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>
      <Modal show={trackDelete} centered>
        <Modal.Header closeButton onClick={trackClose}>
          <Modal.Title>Delete Species</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete ?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="whiteColor"
            style={{ color: "black" }}
            onClick={trackClose}
          >
            No
          </Button>
          <Button variant="primary" onClick={deleteProject}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </>
  );
}
